import React, { useState } from 'react'
// @ts-ignore
import Chart from 'chart.js'
import { Progress, Table, CardHeader, Button, Row } from 'reactstrap'

import { chartOptions, parseOptions } from 'variables/charts'

import moment from 'moment'
// Hooks
import useFetch, { useSWRConfig } from 'hooks/useFetch'

const ChartAppointments = () => {
  const [month, setMonth] = useState(new Date())
  // @ts-ignore
  if (window.Chart) {
    parseOptions(Chart, chartOptions())
  }
  const { mutate } = useSWRConfig()

  const dateFormated = (value: Date) => {
    const dayInit = moment(value).format('01/MM/YYYY')
    const lastDay = moment.utc(value).daysInMonth()
    const dayEnd = moment(value).format(`${lastDay}/MM/YYYY`)
    return `start=${dayInit}&end=${dayEnd}`
  }
  const { data } = useFetch(
    `manager/reports/dash/schedulings?${dateFormated(month)}`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const getPorcent = (value: any, total: any) =>
    value ? ((Number(value) / Number(total)) * 100).toFixed(1) : 0

  const changeDate = (value: any) => {
    setMonth(value)
    mutate(`manager/reports/dash/schedulings?${dateFormated(value)}`)
  }

  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')

  const pending =
    Number(data?.appointments?.total || 0) -
    Number(data?.appointments?.finished || 0) -
    Number(data?.appointments?.canceled || 0)

  return (
    <>
      <CardHeader className="border-0">
        <Row className=" align-items-center d-flex justify-content-between">
          <div className="col">
            <h3 className="mb-0">Consultas</h3>
          </div>
          <div className="d-flex align-items-center">
            <Button
              className="btn-icon btn-2"
              color="primary"
              size="sm"
              onClick={() => {
                changeDate(moment(month).subtract(1, 'month'))
              }}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <h4 style={{ whiteSpace: 'nowrap' }} className="mb-0">
              {moment(month).format('MMM YYYY')}
            </h4>
            <Button
              className="btn-icon btn-2 ml-1"
              color="primary"
              size="sm"
              disabled={verifyMonth}
              onClick={() => {
                changeDate(moment(month).add(1, 'month'))
              }}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <tbody>
          <tr>
            <th scope="row">Finalizadas</th>
            <td>{data?.appointments?.finished}</td>
            <td>
              <div className="d-flex align-items-center justify-content-end">
                <span className="mr-2">
                  {`${getPorcent(
                    data?.appointments?.finished,
                    data?.appointments?.total
                  )}%`}
                </span>
                <div>
                  <Progress
                    max="100"
                    value={getPorcent(
                      data?.appointments?.finished,
                      data?.appointments?.total
                    )}
                    barClassName="bg-gradient-success"
                  />
                </div>
              </div>
            </td>
          </tr>
          {verifyMonth && (
            <tr>
              <th scope="row">Atendimentos Futuros</th>
              <td>{pending}</td>
              <td>
                <div className="d-flex align-items-center justify-content-end">
                  <span className="mr-2">
                    {`${getPorcent(pending, data?.appointments?.total)}%`}
                  </span>
                  <div>
                    <Progress
                      max="100"
                      value={getPorcent(pending, data?.appointments?.total)}
                      barClassName="bg-gradient-danger"
                    />
                  </div>
                </div>
              </td>
            </tr>
          )}
          <tr>
            <th scope="row">Canceladas</th>
            <td>{data?.appointments?.canceled}</td>
            <td>
              <div className="d-flex align-items-center justify-content-end">
                <span className="mr-2">
                  {`${getPorcent(
                    data?.appointments?.canceled,
                    data?.appointments?.total
                  )}%`}
                </span>
                <div>
                  <Progress
                    max="100"
                    value={getPorcent(
                      data?.appointments?.canceled,
                      data?.appointments?.total
                    )}
                    barClassName="bg-gradient-danger"
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">No-Show</th>
            <td>{data?.appointments?.no_show}</td>
            <td>
              <div className="d-flex align-items-center justify-content-end">
                <span className="mr-2">
                  {`${getPorcent(
                    data?.appointments?.no_show,
                    data?.appointments?.total
                  )}%`}
                </span>
                <div>
                  <Progress
                    max="100"
                    value={getPorcent(
                      data?.appointments?.no_show,
                      data?.appointments?.total
                    )}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
export default ChartAppointments
