import React, { useEffect, useState } from 'react'
import { cpfCnpjMask } from 'js-essentials-functions'
import { Link, useHistory } from 'react-router-dom'
import api from 'configs/api'

// Components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
} from 'reactstrap'

import PaginationComponent from 'components/PaginationComponent'
import PermissionComponent from 'components/PermissionComponent'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'

import { handleEnterPress } from 'utils/enterPress'

import { alertWarning } from 'utils/toast'

const Nurses = () => {
  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)

  const history = useHistory()

  const [datas, setDatas] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')

  const limit = 10

  const onClickDoctor = (e: any) => {
    console.log(e)

    const nameFormat = e.name?.replaceAll(' ', '-').toLowerCase()

    history.push(`/admin/enfermeira/${nameFormat}`, {
      params: e,
    })
  }

  const getNurses = async (document = '', initialPage = page) => {
    const dataFilter = document ? `&filter=${document}` : ''
    try {
      setLoading(true)
      const response = await api.get(
        `/manager/nursings?page=${initialPage}&limit=${limit}${dataFilter}`
      )
      const { items, total } = response.data
      setNumberOfPages(Math.ceil(total / limit))
      if (Math.ceil(total / limit) === 1) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }
      setDatas(items)
    } catch (e: any) {
      alertWarning(e?.response?.data?.message || 'Erro ao buscar Enfermeiras')
    }
    setLoading(false)
  }

  const handleSearch = () => {
    getNurses(filter, 1)
  }

  useEffect(() => {
    getNurses()
  }, [page])

  if (loading) return <Loading title="Carregando Enfermeiras..." />
  return (
    <div>
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <PermissionComponent role="ADMIN,SUPPORT,SUPERADMIN">
              <Link to="enfermeira/nova">
                <Button color="secondary">
                  <i className="fas fa-plus" />
                </Button>
              </Link>
            </PermissionComponent>
          </Col>
          <Col sm="auto" className="d-flex justify-content-end">
            <Col xs="10">
              <Input
                type="text"
                id="filter"
                value={filter}
                placeholder="Filtrar pelo nome ou CPF..."
                onChange={(e) => setFilter(e.target.value)}
                onKeyDown={(e) => handleEnterPress(e, handleSearch)}
              />
            </Col>
            <Col xs="auto">
              <Button color="secondary" onClick={() => getNurses(filter, 1)}>
                <i className="fas fa-search" />
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => {
                  if (page === 1) {
                    getNurses('', 1)
                  }
                  setPage(1)
                  setFilter('')
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </Col>
          </Col>
        </Row>
      </Container>

      <Container className="mt-3" fluid>
        <Card className="shadow">
          <>
            <CardHeader className="bg-transparent border-0">
              <Row className="d-flex align-items-center justify-content-between">
                <Col sm="auto" className="mb-2">
                  <h3 className="mb-0 text-black">Enfermeiras Cadastradas</h3>
                </Col>
              </Row>
            </CardHeader>
            <Row className="mt-0">
              <div className="col">
                <Card className="shadow">
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    hover
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">id</th>
                        <th scope="col">nome</th>
                        <th scope="col">email</th>
                        <th scope="col">cpf</th>
                        <th scope="col">status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datas &&
                        datas.map((d) => (
                          <tr
                            key={d.id}
                            onClick={() => onClickDoctor(d)}
                            style={{ cursor: 'pointer' }}
                          >
                            <td>{d.id}</td>
                            <td>{d.name}</td>
                            <td>{d.email}</td>
                            <td>{cpfCnpjMask(d.profiles?.document || '')}</td>
                            <td>
                              {d.status === 'active' ? 'Ativo' : 'Inativo'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {paginationOn && (
                    <PaginationComponent
                      numberOfPages={numberOfPages}
                      page={page}
                      setPage={setPage}
                    />
                  )}
                </Card>
              </div>
            </Row>
          </>
        </Card>
      </Container>
    </div>
  )
}

export default Nurses
