import React, { useState, useMemo, useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Input,
  Form,
  CardBody,
  Label,
} from 'reactstrap'
import api from 'configs/api'
import Loading from 'components/Loading'
import { useLocation } from 'react-router-dom'
import { alertSuccess, alertWarning } from 'utils/toast'
import moment from 'moment'
import { dateMask } from 'js-essentials-functions'
import { FormataStringData } from 'utils/formatData'

const schemaRegister = yup.object().shape({
  due_day: yup
    .number()
    .moreThan(0, 'O dia deve ser entre 1 ao 30')
    .lessThan(31, 'Deve ser menor ou igual 30')
    .required('Campo obrigatório'),
  previous_days: yup
    .number()
    .moreThan(0, 'Deve ser maior que 0')
    .required('Campo obrigatório'),
  min_lives: yup
    .number()
    .moreThan(0, 'Deve ser maior que 1')
    .required('Campo obrigatório'),
  months_freetrail: yup.number().when('with_freetrail', {
    is: true,
    then: yup
      .number()
      .moreThan(0, 'Deve ser maior que 0')
      .required('Valor obrigatório'),
    otherwise: yup.number(),
  }),
  amount_gympass: yup.number().when('with_gympass', {
    is: true,
    then: yup.number().required('Valor obrigatório'),
    otherwise: yup.number(),
  }),
  amount: yup
    .number()
    .moreThan(0, 'Deve ser maior que 0')
    .required('Valor obrigatório'),
  fine_month: yup
    .number()
    // eslint-disable-next-line consistent-return
    .when('fine', (fine, schema) => {
      if (fine > 0) return schema.moreThan(0, 'Deve ser maior que 0')
    }),
  contract_date: yup.string().required('Campo obrigatório'),
  email: yup.string().email('Email inválido').required('Campo obrigatório'),
  with_totalpass: yup.boolean(),
  amount_totalpass: yup.number().when('with_totalpass', {
    is: true,
    then: yup.number().required('Valor obrigatório'),
    otherwise: yup.number(),
  }),
})

const FinancialCompany = ({ company }: any) => {
  const [editable, setEditable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasRole, setHasRole] = useState(false)

  const params: any = useLocation()

  const companyId =
    params.pathname.split('/')[params.pathname.split('/').length - 1]

  const initialValues = useMemo(
    () => ({
      due_day: 0,
      previous_days: 0,
      fine: 0,
      fine_month: 0,
      with_weburn: false,
      with_freetrail: false,
      months_freetrail: 0,
      with_gympass: false,
      amount_gympass: 0,
      amount: 0,
      amount_dependent: 0,
      min_lives: 0,
      contract_date: '',
      email: '',
      with_totalpass: false,
      amount_totalpass: 0,
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)

      const data = {
        company_id: companyId,
        financial_email: values.email,
        due_day: String(values.due_day),
        previous_days: String(values.previous_days),
        fine: String(values.fine),
        fine_month: String(values.fine_month),
        with_weburn: String(values.with_weburn),
        with_gympass: String(values.with_gympass),
        amount_gympass: String(values.amount_gympass),
        with_totalpass: String(values.with_totalpass),
        amount_totalpass: String(values.amount_totalpass),
        with_freetrail: String(values.with_freetrail),
        days_freetrail: String(values.months_freetrail),
        amount: String(values.amount),
        amount_dependent: String(values.amount_dependent),
        minimum_people: String(values.min_lives),
        contract_start_at: FormataStringData(values.contract_date),
      }

      try {
        if (hasRole) {
          await api.post(`company/ms/v1/financial-setup`, data)
          alertSuccess('Dados atualizados com sucesso!')
        } else {
          await api.post(`company/ms/v1/financial-setup`, {
            ...data,
          })
          console.log(data)
          setHasRole(true)
          alertSuccess('Dados criado com sucesso!')
        }
      } catch (e: any) {
        alertWarning(e?.response?.data?.message)
      }
      setLoading(false)
      setEditable(false)
    },
  })

  const getRolesCompany = async () => {
    try {
      const { data } = await api.get(
        `/company/ms/v1/financial-setup/${companyId}`
      )

      formik.setValues({
        due_day: data.due_day,
        previous_days: data.previous_days,
        fine: data.fine,
        fine_month: Number(data.fine_month) || 0,
        with_weburn: data.with_weburn,
        with_freetrail: data.with_freetrail,
        months_freetrail: data.days_freetrail,
        with_gympass: data.with_gympass,
        amount_gympass: Number(data.amount_gympass),
        amount: data.amount,
        amount_dependent: data.amount_dependent,
        min_lives: data.minimum_people,
        contract_date: data.contract_start_at
          ? moment.utc(data.contract_start_at).format('DD/MM/YYYY')
          : '',
        email: data.financial_email,
        with_totalpass: data.with_totalpass,
        amount_totalpass: Number(data.amount_totalpass),
      })

      setHasRole(true)
    } catch (err: any) {
      formik.setValues(initialValues)
      setHasRole(false)
    }
  }

  useEffect(() => {
    getRolesCompany()
  }, [company])

  return (
    <div>
      {loading ? (
        <Loading title="Carregando..." />
      ) : (
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className="m-0 p-0">
            <CardHeader
              className="bg-transparent border-0"
              style={{ minHeight: 100 }}
            >
              <Row className="mt-3 align-items-end d-flex justify-content-between">
                <Col sm="auto" className="mb-2">
                  <h3 className="mb-0 text-black">{`${company?.legal_name} | Financeiro`}</h3>
                </Col>
                <Col sm="auto" className="mb-2">
                  {hasRole && (
                    <>
                      {!editable && (
                        <Button
                          color="secondary"
                          style={{ width: 150 }}
                          disabled={!hasRole}
                          onClick={() => {
                            setEditable(true)
                          }}
                        >
                          Editar
                        </Button>
                      )}
                      {editable && (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Salvar
                        </Button>
                      )}
                    </>
                  )}
                  {!hasRole && (
                    <Button
                      color="primary"
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Cadastrar
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="shadow">
              <Row className="align-items-center mt-3">
                <Col sm="auto">
                  <strong>Envio:</strong>
                </Col>
              </Row>
              <Row className="pl-lg-4 mt-2">
                <Col md="4">
                  <FormGroup>
                    <Label for="with_gympass" style={{ fontSize: 14 }}>
                      Email do financeiro da empresa
                    </Label>
                    <Input
                      className="form-control-alternative"
                      name="email"
                      type="text"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={formik.errors.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <div className="input-feedback">
                        {formik.errors.email}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="align-items-center mt-3">
                <Col sm="auto">
                  <strong>Configurações do plano:</strong>
                </Col>
              </Row>
              <Row className="pl-lg-4 mt-2">
                <Col md="2">
                  <FormGroup>
                    <Label for="contract_date" style={{ fontSize: 14 }}>
                      Data do contrato
                    </Label>
                    <Input
                      className="form-control-alternative"
                      name="contract_date"
                      type="text"
                      placeholder="DD/MM/YYYY"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={dateMask(formik.values.contract_date)}
                      error={formik.errors.contract_date}
                    />
                    {formik.errors.contract_date &&
                      formik.touched.contract_date && (
                        <div className="input-feedback">
                          {formik.errors.contract_date}
                        </div>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="pl-lg-4 mt-2">
                <Col md="2">
                  <FormGroup>
                    <Label for="amount" style={{ fontSize: 14 }}>
                      Valor titular
                    </Label>
                    <Input
                      className="form-control-alternative"
                      name="amount"
                      type="number"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.amount}
                      error={formik.errors.amount}
                    />
                    {formik.errors.amount && formik.touched.amount && (
                      <div className="input-feedback">
                        {formik.errors.amount}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label for="amount_dependent" style={{ fontSize: 14 }}>
                      Valor dependente
                    </Label>
                    <Input
                      className="form-control-alternative"
                      name="amount_dependent"
                      type="number"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.amount_dependent}
                      error={formik.errors.amount_dependent}
                    />
                    {formik.errors.amount_dependent &&
                      formik.touched.amount_dependent && (
                        <div className="input-feedback">
                          {formik.errors.amount_dependent}
                        </div>
                      )}
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label for="min_lives" style={{ fontSize: 14 }}>
                      Mínimo de vidas
                    </Label>

                    <Input
                      className="form-control-alternative"
                      name="min_lives"
                      type="number"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.min_lives}
                      error={formik.errors.min_lives}
                    />
                    {formik.errors.min_lives && formik.touched.min_lives && (
                      <div className="input-feedback">
                        {formik.errors.min_lives}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="pl-lg-4 mt-2">
                <Col md="auto">
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Label for="with_gympass" style={{ fontSize: 14 }}>
                      Gympass
                    </Label>
                    <label className="custom-toggle">
                      <input
                        checked={formik.values.with_gympass}
                        disabled={hasRole && !editable}
                        onChange={() => {
                          formik.setValues({
                            ...formik.values,
                            with_gympass: !formik.values.with_gympass,
                          })
                        }}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label for="amount_gympass" style={{ fontSize: 14 }}>
                      Valor do Gympass
                    </Label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">R$</span>
                      </div>
                      <Input
                        className="form-control-alternative"
                        name="amount_gympass"
                        type="number"
                        disabled={
                          (hasRole && !editable) || !formik.values.with_gympass
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.amount_gympass}
                        error={formik.errors.amount_gympass}
                      />
                    </div>
                    {formik.errors.amount_gympass &&
                      formik.touched.amount_gympass && (
                        <div className="input-feedback">
                          {formik.errors.amount_gympass}
                        </div>
                      )}
                  </FormGroup>
                </Col>
                <Col md="auto">
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Label for="with_totalpass" style={{ fontSize: 14 }}>
                      Totalpass
                    </Label>
                    <label className="custom-toggle">
                      <input
                        checked={formik.values.with_totalpass}
                        disabled={hasRole && !editable}
                        onChange={() => {
                          formik.setValues({
                            ...formik.values,
                            with_totalpass: !formik.values.with_totalpass,
                          })
                        }}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label for="amount_totalpass" style={{ fontSize: 14 }}>
                      Valor do Totalpass
                    </Label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">R$</span>
                      </div>
                      <Input
                        className="form-control-alternative"
                        name="amount_totalpass"
                        type="number"
                        disabled={
                          (hasRole && !editable) ||
                          !formik.values.with_totalpass
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.amount_totalpass}
                        error={formik.errors.amount_totalpass}
                      />
                    </div>
                    {formik.errors.amount_totalpass &&
                      formik.touched.amount_totalpass && (
                        <div className="input-feedback">
                          {formik.errors.amount_totalpass}
                        </div>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="pl-lg-4 mt-2">
                <Col md="auto">
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Label for="with_freetrail" style={{ fontSize: 14 }}>
                      Freetrail
                    </Label>
                    <label className="custom-toggle">
                      <input
                        checked={formik.values.with_freetrail}
                        disabled={hasRole && !editable}
                        onChange={() => {
                          formik.setValues({
                            ...formik.values,
                            with_freetrail: !formik.values.with_freetrail,
                          })
                        }}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label for="months_freetrail" style={{ fontSize: 14 }}>
                      Meses grátis
                    </Label>
                    <Input
                      className="form-control-alternative"
                      name="months_freetrail"
                      type="number"
                      disabled={
                        (hasRole && !editable) || !formik.values.with_freetrail
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.months_freetrail}
                      error={formik.errors.months_freetrail}
                    />
                    {formik.errors.months_freetrail &&
                      formik.touched.months_freetrail && (
                        <div className="input-feedback">
                          {formik.errors.months_freetrail}
                        </div>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="align-items-center mt-3">
                <Col sm="auto">
                  <strong>Geração do boleto:</strong>
                </Col>
              </Row>
              <Row className="pl-lg-4 mt-2">
                <Col lg="2">
                  <FormGroup>
                    <Label for="due_day" style={{ fontSize: 14 }}>
                      Dia do vencimento
                    </Label>
                    <Input
                      className="form-control-alternative"
                      name="due_day"
                      type="number"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.due_day}
                      error={formik.errors.due_day}
                    />
                    {formik.errors.due_day && formik.touched.due_day && (
                      <div className="input-feedback">
                        {formik.errors.due_day}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <Label for="previous_days" style={{ fontSize: 14 }}>
                      Gerar dias antes
                    </Label>

                    <Input
                      className="form-control-alternative"
                      name="previous_days"
                      type="number"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.previous_days}
                      error={formik.errors.previous_days}
                    />
                    {formik.errors.previous_days &&
                      formik.touched.previous_days && (
                        <div className="input-feedback">
                          {formik.errors.previous_days}
                        </div>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="align-items-center mt-3">
                <Col sm="auto">
                  <strong>Juros:</strong>
                </Col>
              </Row>
              <Row className="pl-lg-4 mt-2">
                <Col lg="2">
                  <FormGroup>
                    <Label for="fine" style={{ fontSize: 14 }}>
                      Multa (%)
                    </Label>
                    <Input
                      className="form-control-alternative"
                      name="fine"
                      type="number"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.fine}
                      error={formik.errors.fine}
                    />
                    {formik.errors.fine && formik.touched.fine && (
                      <div className="input-feedback">{formik.errors.fine}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <Label for="fine_month" style={{ fontSize: 14 }}>
                      Juros (%)
                    </Label>

                    <Input
                      className="form-control-alternative"
                      name="fine_month"
                      type="number"
                      disabled={hasRole && !editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.fine_month}
                      error={formik.errors.fine_month}
                    />
                    {formik.errors.fine_month && formik.touched.fine_month && (
                      <div className="input-feedback">
                        {formik.errors.fine_month}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Form>
        </FormikProvider>
      )}
    </div>
  )
}

export default FinancialCompany
